import Messages from '@core/scripts/components/messages';
import MessageBroker from '@bonprix/pattern-library/components/message-broker';
import Breakpoint from '@core/scripts/helper/breakpoint.js';

const APP_BANNER_VISIBILITY_CLASS = '-banner-is-opened';
const BANDEROLE_VISIBILITY_CLASS = '-banderole-is-opened';
const APP_BANNER_EXISTS_CLASS = '-banner-exists';
const SCROLL_THRESHOLD = 56;

const headerNode = document.querySelector('[jsh-module="header/header"]');
const enabledForBreakpoints = ['min', 'small', 'medium'];

MessageBroker.subscribe(Messages.APP_BANNER.CLOSE, onRemoveAppBanner);
MessageBroker.subscribe(Messages.WINDOW.RESIZED, checkBodyPadding);

const navigationMessages = [];
let inNavigation = false;

if (document.body.classList.contains(APP_BANNER_VISIBILITY_CLASS)) {
    window.addEventListener('scroll', toggleAppBannerVisibility);
    navigationMessages.push(MessageBroker.subscribe(Messages.NAVIGATION_ACCORDION.OPENED, hideAppBannerForNaviagtion));
    navigationMessages.push(MessageBroker.subscribe(Messages.NAVIGATION_ACCORDION.CLOSED, restoreAppBannerVisibilityPostNavigation));
}

if (document.body.classList.contains(BANDEROLE_VISIBILITY_CLASS)) {
    window.addEventListener('scroll', toggleBanderoleVisibility);
}

function onRemoveAppBanner() {
    document.body.classList.remove(APP_BANNER_VISIBILITY_CLASS);
    document.body.classList.remove(APP_BANNER_EXISTS_CLASS);
    checkBodyPadding();
    window.removeEventListener('scroll', toggleAppBannerVisibility);
    navigationMessages.forEach((message) => MessageBroker.unsubscribe(message));
}

function toggleAppBannerVisibility() {
    if (inNavigation) {
        return;
    }
    if (window.scrollY > SCROLL_THRESHOLD) {
        document.body.classList.remove(APP_BANNER_VISIBILITY_CLASS);
    } else {
        document.body.classList.add(APP_BANNER_VISIBILITY_CLASS);
    }
}

function toggleBanderoleVisibility() {
    if (inNavigation) {
        return;
    }
    if (window.scrollY > SCROLL_THRESHOLD) {
        document.body.classList.remove(BANDEROLE_VISIBILITY_CLASS);
    } else {
        document.body.classList.add(BANDEROLE_VISIBILITY_CLASS);
    }
}

function checkBodyPadding() {
    document.body.style.paddingTop = '';

    const bodyPadding = parseInt(getComputedStyle(document.body).paddingTop);
    const headerHeight = headerNode?.offsetHeight;
    const needsCorrection = headerHeight > bodyPadding + 1 || bodyPadding > headerHeight;

    if (Breakpoint.keyIsIn(enabledForBreakpoints) && needsCorrection) {
        document.body.style.paddingTop = headerHeight + 'px';
    } else {
        document.body.style.paddingTop = '';
    }
}

function hideAppBannerForNaviagtion() {
    inNavigation = true;
    document.body.classList.remove(APP_BANNER_VISIBILITY_CLASS);
    checkBodyPadding();
}

function restoreAppBannerVisibilityPostNavigation() {
    inNavigation = false;
    document.body.style.paddingTop = '';
    toggleAppBannerVisibility();
    checkBodyPadding();
}

checkBodyPadding();
